import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as FullStory from '@fullstory/browser'
import { createGtm } from '@gtm-support/vue-gtm'
import * as Sentry from '@sentry/vue'
import { LDPlugin } from 'launchdarkly-vue-client-sdk'

FullStory.init({ orgId: 'o-1GATH8-na1' })

const app = createApp(App).use(store).use(router)

Sentry.init({
  app,
  dsn: 'https://9e74111a15a1a1c35ec017d52086f4cf@o360216.ingest.sentry.io/4505833645146112',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

// google tag manager
if (process.env.VUE_APP_GTM_ID) {
  app.use((()=>{
  try {
    createGtm({
      id: process.env.VUE_APP_GTM_ID,
      enabled: true,
      debug: false,
      vueRouter: router,
    })
  } catch (error) {
    console.log(error)
  }
 })
  )
}

const clientSideID = process.env.VUE_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID || '656db1e32b28ba1016418400';
app.use(LDPlugin, { clientSideID });

// google analytics
const gaId = process.env.VUE_APP_GA4_ID
if (gaId) {
  const script = document.createElement('script')
  script.async = true
  script.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`
  document.head.appendChild(script)

  const configScript = document.createElement('script')
  configScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${gaId}');
  `
  document.head.appendChild(configScript)
}

app.config.globalProperties.$FullStory = FullStory

app.mount('#app')
